import { createBrowserRouter } from "react-router-dom"
import Login from "../main/login"
import App from "../main/app"
import UserSettings from "../main/userSettings"
import Customers from "../main/customers"
import Customer from "../main/customer"
import Products from "../main/products"
import Quote from "../main/quote"
import OrderList from "../main/orderList"

export default function router() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Login />
        },
        {
            path: "quote/:customerId",
            element: <Quote />,
        },
        {
            path: "orderlist/:customerId",
            element: <OrderList />,
        },
        {
            element: <App />,
            children: [
                {
                    path: "settings",
                    element: <UserSettings />
                },
                {
                    path: "customers",
                    element: <Customers />
                },
                {
                    path: "customer/:customerId",
                    element: <Customer />,
                },
                {
                    path: "customer/:customerId/:pageNumber",
                    element: <Customer />,
                },
                {
                    path: "addhardware/:hardwareType/:customerId/:pageNumber",
                    element: <Products />,
                }
            ]
        },
    ])

    return router
}