import axios from "axios"

export function GetUserSettings(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/usersettings?&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateUserSettings(token, userSettingsData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/usersettings"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: userSettingsData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetCustomersList(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers/all?id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetCustomer(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers?id=",customerId,"&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateCustomer(token, customerData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/customers"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: customerData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetProductsList(token, categoryId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/products/bycategory?id_category=", categoryId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetProduct(token, productId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/products?id=", productId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetHardware(token, customerId, productId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/bycustomerandproduct?id_owner=", token.payload.sub, "&id_customer=", customerId, "&id_product=", productId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetAllHardwareList(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/bycustomer?id_owner=", token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetHardwareList(token, customerId, hardwareType) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware/bycustomerandtype?id_owner=", token.payload.sub, "&id_customer=", customerId, "&hardware_type=", hardwareType),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetSuppliersList(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/suppliers?id_owner=", token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateHardware(token, hardwareData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: hardwareData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteHardware(token, hardwareId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/hardware?id=", hardwareId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetEmployeesList(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees/bycustomer?id_owner=", token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateEmployee(token, employeeData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: employeeData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteEmployee(token, employeeId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/employees?id=", employeeId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetCashDrawersList(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cashdrawers/bycustomer?id_owner=", token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateCashDrawer(token, cashDrawerData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cashdrawers"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: cashDrawerData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteCashDrawer(token, cashDrawerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/cashdrawers?id=", cashDrawerId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}


export function GetQuoteConditionsList(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quoteconditions/bycustomer?id_owner=", token.payload.sub, "&id_customer=", customerId),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function UpdateQuoteCondition(token, conditionData) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quoteconditions"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: conditionData
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function DeleteQuoteCondition(token, conditionId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/quoteconditions?id=", conditionId, "&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function GetCustomerDataFromCoc(token, customerId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_INVENTARISATION_API_BASE_ENV.concat("/chamberofcommerce/import?id_customer=",customerId,"&id_owner=", token.payload.sub),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

export function ConditionsMet(customerData, conditions) {
  if(conditions) {
    for(var i = 0; i < conditions.length; i++) {  
      if(conditions[i].operation === "=" && customerData?.[conditions[i].field] !== conditions[i].value) {
        return false
      }
      if(conditions[i].operation === ">" && customerData?.[conditions[i].field] <= conditions[i].value) {
        return false
      }
      if(conditions[i].operation === "<" && customerData?.[conditions[i].field] >= conditions[i].value) {
        return false
      }
      if(conditions[i].operation === ">=" && customerData?.[conditions[i].field] < conditions[i].value) {
        return false
      }
      if(conditions[i].operation === "<=" && customerData?.[conditions[i].field] > conditions[i].value) {
        return false
      }
    }
  }
  return true
}