import Card from "../components/card"
import ErrorMessage from "../components/errorMessage"
import FieldParser from "../components/fieldParser"
import { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { ConditionsMet, UpdateCustomer, UpdateHardware, DeleteHardware, UpdateEmployee, DeleteEmployee, UpdateCashDrawer, DeleteCashDrawer, UpdateQuoteCondition, DeleteQuoteCondition, GetCustomerDataFromCoc } from "../helpers/dataHandlers/Inventarisation"
import { getCustomer, setCustomer, getHardware, setHardware, getEmployees, setEmployees, getCashDrawers, setCashDrawers, getQuoteConditions, setQuoteConditions } from "../redux/dataslices/inventarisationSlice"
import { GetCustomer, GetHardwareList, GetEmployeesList, GetCashDrawersList, GetQuoteConditionsList } from "../helpers/dataHandlers/Inventarisation"
import { TranslateCustom as tc } from '../helpers/translation'
import { v4 as uuidv4 } from 'uuid'

function Customer(props) {
  const {customerId} = useParams()
  const {pageNumber} = useParams()
  const dispatch = useDispatch()
  const token = useSelector(getToken)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const customer = useSelector(getCustomer)
  const hardware = useSelector(getHardware)
  const employees = useSelector(getEmployees)
  const cashDrawers = useSelector(getCashDrawers)
  const quoteConditions = useSelector(getQuoteConditions)
  const [customerData, setCustomerData] = useState()
  const [hardwareData, setHardwareData] = useState()
  const [employeesData, setEmployeesData] = useState()
  const [cashDrawersData, setCashDrawersData] = useState()
  const [quoteConditionsData, setQuoteConditionsData] = useState()
  const [dirtyState, setDirtyState] = useState()
  const context = useOutletContext()
  var pageData
  var requestsLeftToSend = 0
  try {
    pageData = require('../pages/'.concat(pageNumber,'.json'))
  }
  catch(e) {
    pageData = null
  }

  const updateDataField = (field, value) => {
    var field_split = field.split("_")
    setDirty(field_split[0],field_split[1],true)
    var dataToUpdate = []  
    switch(field_split[0]) {
      case 'customer':      
        setCustomerData({...customerData, [field_split.slice(2).join("_")]:value} )
        break
      case 'hardware':
        dataToUpdate = [...hardwareData]
        dataToUpdate[field_split[1]] = {...hardwareData[field_split[1]], [field_split.slice(2).join("_")]:value}
        setHardwareData(dataToUpdate)
        break
      case 'employees':
        dataToUpdate = [...employeesData]
        dataToUpdate[field_split[1]] = {...employeesData[field_split[1]], [field_split.slice(2).join("_")]:value}
        setEmployeesData(dataToUpdate)
        break
      case 'cashdrawers':
        dataToUpdate = [...cashDrawersData]
        dataToUpdate[field_split[1]] = {...cashDrawersData[field_split[1]], [field_split.slice(2).join("_")]:value}
        setCashDrawersData(dataToUpdate)
        break
      case 'quoteconditions':
        dataToUpdate = [...quoteConditionsData]
        dataToUpdate[field_split[1]] = {...quoteConditionsData[field_split[1]], [field_split.slice(2).join("_")]:value}
        setQuoteConditionsData(dataToUpdate)
        break
      default:
        break
    }
  }

  const swapDataFields = (field_a, field_b) => {
    var field_a_split = field_a.split("_")
    var field_b_split = field_b.split("_")
    setDirty(field_a_split[0],field_a_split[1],true)
    setDirty(field_b_split[0],field_b_split[1],true)
    var value_a
    var value_b
    switch(field_a_split[0]) {
      case 'customer':      
        value_a = customerData[field_a_split.slice(2).join("_")]
        break
      case 'hardware':
        value_a = hardwareData[[field_a_split[1]]][field_a_split.slice(2).join("_")]
        break
      case 'employees':
        value_a = employeesData[[field_a_split[1]]][field_a_split.slice(2).join("_")]
        break
      case 'cashdrawers':
        value_a = cashDrawersData[[field_a_split[1]]][field_a_split.slice(2).join("_")]
        break
      case 'quoteconditions':
        value_a = quoteConditionsData[[field_a_split[1]]][field_a_split.slice(2).join("_")]
        break
      default:
        break
    }
    switch(field_b_split[0]) {
      case 'customer':      
        value_b = customerData[field_b_split.slice(2).join("_")]
        break
      case 'hardware':
        value_b = hardwareData[[field_b_split[1]]][field_b_split.slice(2).join("_")]
        break
      case 'employees':
        value_b = employeesData[[field_b_split[1]]][field_b_split.slice(2).join("_")]
        break
      case 'cashdrawers':
        value_b = cashDrawersData[[field_b_split[1]]][field_b_split.slice(2).join("_")]
        break
      case 'quoteconditions':
        value_b = quoteConditionsData[[field_b_split[1]]][field_b_split.slice(2).join("_")]
        break
      default:
        break
    }
    var dataToUpdate
    if(field_a_split[0] === 'customer') {
      setCustomerData({...customerData, [field_a_split.slice(2).join("_")]:value_b} )
    }
    if(field_b_split[0] === 'customer') {
        setCustomerData({...customerData, [field_a_split.slice(2).join("_")]:value_a} )
    }
    if(field_a_split[0] === 'hardware' || field_b_split[0] === 'hardware') {
      dataToUpdate = [...hardwareData]
      if(field_a_split[0] === 'hardware') {
        dataToUpdate[field_b_split[0] === 'hardware' ? field_b_split[1] : field_a_split[1]] = {...hardwareData[field_a_split[1]], [field_a_split.slice(2).join("_")]:value_b}
      }
      if(field_b_split[0] === 'hardware') {
        dataToUpdate[field_a_split[0] === 'hardware' ? field_a_split[1] : field_b_split[1]] = {...hardwareData[field_b_split[1]], [field_b_split.slice(2).join("_")]:value_a}
      }
      setHardwareData(dataToUpdate)
    }
    if(field_a_split[0] === 'employees' || field_b_split[0] === 'employees') {
      dataToUpdate = [...employeesData]
      if(field_a_split[0] === 'employees') {
        dataToUpdate[field_b_split[0] === 'employees' ? field_b_split[1] : field_a_split[1]] = {...employeesData[field_a_split[1]], [field_a_split.slice(2).join("_")]:value_b}
      }
      if(field_b_split[0] === 'employees') {
        dataToUpdate[field_a_split[0] === 'employees' ? field_a_split[1] : field_b_split[1]] = {...employeesData[field_b_split[1]], [field_b_split.slice(2).join("_")]:value_a}
      }
      setEmployeesData(dataToUpdate)
    }
    if(field_a_split[0] === 'cashdrawers' || field_b_split[0] === 'cashdrawers') {
      dataToUpdate = [...cashDrawersData]
      if(field_a_split[0] === 'cashdrawers') {
        dataToUpdate[field_b_split[0] === 'cashdrawers' ? field_b_split[1] : field_a_split[1]] = {...cashDrawersData[field_a_split[1]], [field_a_split.slice(2).join("_")]:value_b}
      }
      if(field_b_split[0] === 'cashdrawers') {
        dataToUpdate[field_a_split[0] === 'cashdrawers' ? field_a_split[1] : field_b_split[1]] = {...cashDrawersData[field_b_split[1]], [field_b_split.slice(2).join("_")]:value_a}
      }
      setCashDrawersData(dataToUpdate)
    }
    if(field_a_split[0] === 'quoteconditions' || field_b_split[0] === 'quoteconditions') {
      dataToUpdate = [...quoteConditionsData]
      if(field_a_split[0] === 'quoteconditions') {
        dataToUpdate[field_b_split[0] === 'quoteconditions' ? field_b_split[1] : field_a_split[1]] = {...quoteConditionsData[field_a_split[1]], [field_a_split.slice(2).join("_")]:value_b}
      }
      if(field_b_split[0] === 'quoteconditions') {
        dataToUpdate[field_a_split[0] === 'quoteconditions' ? field_a_split[1] : field_b_split[1]] = {...quoteConditionsData[field_b_split[1]], [field_b_split.slice(2).join("_")]:value_a}
      }
      setQuoteConditionsData(dataToUpdate)
    }
  }

  const setDirty = (database, recid, value) => {
    var dataToUpdate = []
    dataToUpdate = dirtyState
    if(!dataToUpdate) {
      dataToUpdate = []
    }
    if(!dataToUpdate[database]) {
      dataToUpdate[database] = []
    }
    dataToUpdate[database][recid] = value
    setDirtyState(dataToUpdate)
  }

  const removeDirty = (database, recid) => {
    var dataToUpdate = []
    dataToUpdate = dirtyState
    dataToUpdate[database].splice(recid,1)
    setDirtyState(dataToUpdate)
  }

  const updateCustomer = (dataObject, callback) => {
    UpdateCustomer(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const navigateToHardwareSelection = (hardware_type) => {
    if(customerData) {
      navigate("/addhardware/".concat(hardware_type, "/",customerData.id,"/",pageNumber))
    }
  }

  const updateHardware = (dataObject, callback) => {
    requestsLeftToSend ++
    UpdateHardware(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const deleteHardware = (hardwareId) => {
    var databaseId = hardwareData[hardwareId].id
    var dataToUpdate = [...hardwareData]
    dataToUpdate.splice(hardwareId,1)
    setHardwareData(dataToUpdate)
    removeDirty("hardware", hardwareId)
    requestsLeftToSend ++
    DeleteHardware(token, databaseId)
    .then(response => { dispatch(requestCompleted()) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const createEmployee = (callback) => {
    requestsLeftToSend ++
    const recid = employeesData.length
    const dataObject = { id: uuidv4(), id_owner: customerData.id_owner, id_customer: customerData.id }
    var dataToUpdate = [...employeesData]
    dataToUpdate[recid] = dataObject
    setEmployeesData(dataToUpdate)
    setDirty("employees", recid, false)
    UpdateEmployee(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const updateEmployee = (dataObject, callback) => {
    requestsLeftToSend ++
    UpdateEmployee(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const deleteEmployee = (employeeId) => {
    var databaseId = employeesData[employeeId].id
    var dataToUpdate = [...employeesData]
    dataToUpdate.splice(employeeId,1)
    setEmployeesData(dataToUpdate)
    removeDirty("employees", employeeId)
    requestsLeftToSend ++
    DeleteEmployee(token, databaseId)
    .then(response => { dispatch(requestCompleted()) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const createCashDrawer = (callback) => {
    requestsLeftToSend ++
    const recid = cashDrawersData.length
    const dataObject = { id: uuidv4(), id_owner: customerData.id_owner, id_customer: customerData.id }
    var dataToUpdate = [...cashDrawersData]
    dataToUpdate[recid] = dataObject
    setCashDrawersData(dataToUpdate)
    setDirty("cashdrawers", recid, false)
    UpdateCashDrawer(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }
  
  const updateCashDrawer = (dataObject, callback) => {
    requestsLeftToSend ++
    UpdateCashDrawer(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const deleteCashDrawer = (cashDrawerId) => {
    var databaseId = cashDrawersData[cashDrawerId].id
    var dataToUpdate = [...cashDrawersData]
    dataToUpdate.splice(cashDrawerId,1)
    setCashDrawersData(dataToUpdate)
    removeDirty("cashdrawers", cashDrawerId)
    requestsLeftToSend ++
    DeleteCashDrawer(token, databaseId)
    .then(response => { dispatch(requestCompleted()) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const createQuoteCondition = (callback) => {
    requestsLeftToSend ++
    const recid = quoteConditionsData.length
    const dataObject = { id: uuidv4(), id_owner: customerData.id_owner, id_customer: customerData.id, condition_order: (recid > 0) ? quoteConditionsData[recid - 1].condition_order + 1 : 1 }
    var dataToUpdate = [...quoteConditionsData]
    dataToUpdate[recid] = dataObject
    setQuoteConditionsData(dataToUpdate)
    setDirty("cashdrawers", recid, false)
    UpdateQuoteCondition(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }
  
  const updateQuoteCondition = (dataObject, callback) => {
    requestsLeftToSend ++
    UpdateQuoteCondition(token, dataObject)
    .then(response => { dispatch(requestCompleted(callback)) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const deleteQuoteCondition = (quoteConditionId) => {
    var databaseId = quoteConditionsData[quoteConditionId].id
    var dataToUpdate = [...quoteConditionsData]
    dataToUpdate.splice(quoteConditionId,1)
    setQuoteConditionsData(dataToUpdate)
    removeDirty("quoteconditions", quoteConditionId)
    requestsLeftToSend ++
    DeleteQuoteCondition(token, databaseId)
    .then(response => { dispatch(requestCompleted()) })
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const getDataFromCoc = async () => {
    GetCustomerDataFromCoc(token, customerId)
      .then(response => { dispatch(setCustomer(response.data)) })
      .catch(error => {
        enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      })
  }

  const requestCompleted = (callback) => {
    requestsLeftToSend --
    if(requestsLeftToSend === -1) {
      requestsLeftToSend = 0
      if(callback != null) {
        callback()
      }
    }
    return {
      type: "requestCompleted"
    }
  }

  const redirectPage = useCallback(() => {  
    if(pageNumber === "customers" || pageNumber === "settings") {
      navigate("/" + pageNumber)
    }
    else {
      navigate("/customers")
    }
  },[pageNumber, navigate])

  const newCustomerCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'create_new_customer',
    loading: loading,
    loadingText: "customer_being_created",
    data: <ErrorMessage data={'creating_customer_failed'} />
  }

  const CustomerCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: tc(pageData?.title),
    loading: loading,
    loadingText: 'loading_customer',
    data: <FieldParser 
      pageNumber={pageNumber}
      pageConfig={pageData}
      dirtyState={dirtyState}
      updateDataField={updateDataField}
      requestCompleted={requestCompleted} 
      customerData={customerData}
      updateCustomer={updateCustomer}
      hardwareData={hardwareData}
      navigateToHardwareSelection={navigateToHardwareSelection}
      updateHardware={updateHardware}
      deleteHardware={deleteHardware}
      employeesData={employeesData}
      createEmployee={createEmployee}
      updateEmployee={updateEmployee}
      deleteEmployee={deleteEmployee}
      cashDrawersData={cashDrawersData}
      createCashDrawer={createCashDrawer}
      updateCashDrawer={updateCashDrawer}
      deleteCashDrawer={deleteCashDrawer}
      quoteConditionsData={quoteConditionsData}
      createQuoteCondition={createQuoteCondition}
      updateQuoteCondition={updateQuoteCondition}
      deleteQuoteCondition={deleteQuoteCondition}
      swapDataFields={swapDataFields}
      getDataFromCoc={getDataFromCoc}
    />
  }

  useEffect(() => {
    if(pageData !== null) {
      const fetchCustomer = async () => {
        GetCustomer(token, customerId)
          .then(response => { dispatch(setCustomer(response.data)) })
          .catch(error => {
            enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
            redirectPage()
          })
      }
      const fetchHardware = async (hardwareType) => {
        GetHardwareList(token, customerId, hardwareType)
          .then(response => { dispatch(setHardware(response.data)) })
          .catch(error => {
            enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
            setLoading(false)
          })
      }
      const fetchEmployees = async () => {
        GetEmployeesList(token, customerId)
          .then(response => { dispatch(setEmployees(response.data)) })
          .catch(error => {
            enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
            setLoading(false)
          })
      }
      const fetchCashDrawers = async () => {
        GetCashDrawersList(token, customerId)
          .then(response => { dispatch(setCashDrawers(response.data)) })
          .catch(error => {
            enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
            setLoading(false)
          })
      }
      const fetchQuoteConditions = async () => {
        GetQuoteConditionsList(token, customerId)
          .then(response => { dispatch(setQuoteConditions(response.data)) })
          .catch(error => {
            enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
            setLoading(false)
          })
      }
      setDirtyState([])
      fetchCustomer()
      pageData.fields.every(field => {if(field.database === "hardware"){fetchHardware(field.database_filter); return false} else {return true}})
      pageData.fields.every(field => {if(field.database === "employees"){fetchEmployees(); return false} else {return true}})
      pageData.fields.every(field => {if(field.database === "cashdrawers"){fetchCashDrawers(); return false} else {return true}})
      pageData.fields.every(field => {if(field.database === "quoteconditions"){fetchQuoteConditions(); return false} else {return true}})
    }
    else {
      redirectPage()
    }
    // eslint-disable-next-line
  }, [token, pageData])
  
  useEffect(() => {
    if (!customer) return
    setLoading(false)
    setCustomerData(customer)
    if(pageNumber === 1) {
      setHardwareData()
      setEmployeesData()
      setCashDrawersData()
      setQuoteConditionsData()
    }
    context.setCustomerData(customer)
    // eslint-disable-next-line
  }, [customer])
  
  useEffect(() => {
    if (!hardware) return
    let hardwareList = []
    for (const [key, value] of Object.entries(hardware)) {
      hardwareList.push(value)
      setDirty("hardware", key, false)
    }
    setHardwareData(hardwareList)

    // eslint-disable-next-line
  }, [hardware])
  
  useEffect(() => {
    if (!employees) return
    let employeesList = []
    for (const [key, value] of Object.entries(employees)) {
      employeesList.push(value)
      setDirty("employees", key, false)
    }
    setEmployeesData(employeesList)

    // eslint-disable-next-line
  }, [employees])
  
  useEffect(() => {
    if (!cashDrawers) return
    let cashDrawersList = []
    for (const [key, value] of Object.entries(cashDrawers)) {
      cashDrawersList.push(value)
      setDirty("cashdrawers", key, false)
    }
    setCashDrawersData(cashDrawersList)

    // eslint-disable-next-line
  }, [cashDrawers])
  
  useEffect(() => {
    if (!quoteConditions) return
    let quoteConditionsList = []
    for (const [key, value] of Object.entries(quoteConditions)) {
      quoteConditionsList.push(value)
      setDirty("quoteconditions", key, false)
    }
    setQuoteConditionsData(quoteConditionsList)

    // eslint-disable-next-line
  }, [quoteConditions])

  useEffect(() => {
    if (!customerData) return
    if(!ConditionsMet(customerData, pageData?.conditions)) {
      redirectPage()
    }
  }, [customerData, pageData, redirectPage])

  return (
    (customerId === 'new') ?
    <>
      <Card {...newCustomerCardProps} />
    </>
    :
    <>
      <Card {...CustomerCardProps} />
    </>
  )
}

export default Customer
