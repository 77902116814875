import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { useEffect, useState, useCallback } from "react"
import { Button, Box, CssBaseline, CircularProgress, TextField, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { store } from '../redux/store'
import i18n from "i18next"

export default function UserSettingsPanel(props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const onClickReturnToCustomers = () => {
    savePageData("/customers")
  }

  const savePageData = useCallback((redirect = null) => {
    props?.updateUserSettings({ 
      id_owner: props?.id_owner,
      user_language: props?.userSettingsData?.user_language
    },redirect)
    setLoading(false)
  },[props])

  const onPageUnload = useCallback(() => {
    savePageData()
  },[savePageData])

  const handleSelectFieldEdit = (event) => {
    props.updateDataField(event.target.name, event.target.value)
  }
  
  useEffect(() => {
    window.addEventListener("beforeunload", onPageUnload)
    return () => {
      window.removeEventListener("beforeunload", onPageUnload)
    }
  }, [onPageUnload])

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box
        className="field-container"
        sx={{ ml: -0.5, mr: -0.5, mt: 1 }}>
        <Box className="field-block">
          <TextField
            className={"resizableChild input-select"}
            select
            label={t("language")}
            id={"user_language"}
            name={"user_language"}
            onChange={handleSelectFieldEdit}
            value={props?.userSettingsData?.user_language ? props?.userSettingsData?.user_language : i18n.language}
            sx={{mt: 1, mb: 1, ml: 0.5, mr: 0.5, textAlign: "left", input: {textAlign: "left"}}}
          >
            {store.languages.map((language) => (
              <MenuItem
              value={language}
              key={language}>
                {t("language_select_" + language)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Box sx={{ height: '37px', mt: 1, mb: 1, textAlign: 'center', position: 'relative' }}>
        <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => onClickReturnToCustomers()}
          sx={{ borderRadius: '9px', position: 'absolute', left: 0}}
        >
          {loading ? (
            <CircularProgress color='inherit' size={32} />
          ) : (
            t('return_to_customers')
          )}
        </Button>
      </Box>
    </ThemeProvider>
  )
}