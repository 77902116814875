// import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import NavbarDrawer from '../components/drawer'
import Navbar from '../components/navbar'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../redux/dataslices/tokenSlice'
import { useEffect, useState } from "react"
import { TokenExpiredCheck } from '../helpers/dataHandlers/KeycloakAuth'
import { getUserSettings, setUserSettings } from "../redux/dataslices/inventarisationSlice"
import { GetUserSettings } from "../helpers/dataHandlers/Inventarisation"
import { useSnackbar } from "notistack"
import { changeLanguage } from 'i18next'
// import { getToken } from '../redux/dataslices/token'


function App() {
  const token = useSelector(getToken)
  const { enqueueSnackbar } = useSnackbar()
  const userSettings = useSelector(getUserSettings)
  const dispatch = useDispatch()
  const [customerData, setCustomerData] = useState()

  const loadUserSettings = async () => {
    GetUserSettings(token)
      .then(response => { dispatch(setUserSettings(response.data)) })
      .catch(error => {
        enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      })
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {  // Assign interval to a variable to clear it.
      await TokenExpiredCheck({ token: token, dispatch: dispatch })
    }, 10000)

    return () => clearInterval(intervalId) // This is important
  }, [dispatch, token])
  
  useEffect(() => {
    if(userSettings?.user_language) {
      changeLanguage(userSettings.user_language)
    }
  }, [userSettings])

  if (!token) {
    return (
      <div>
        <Navigate to='/' />
      </div>
    )
  }

  if (!userSettings) {
    loadUserSettings()
  }

  return (
    <>
      <Navbar />
      <Outlet context={{setCustomerData: setCustomerData}}/>
      <NavbarDrawer customerData={customerData} />
    </>
  )
}

export default App
