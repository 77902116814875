import Box from '@mui/material/Box'
import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { Button, CssBaseline, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getDrawerOpen, setDrawerOpen } from '../redux/dataslices/drawerSlice'
import CopyrightText from './copyrightText'
import VersionText from './versionText'
import ActablueLogoNoText from '../static/icons/logo_png_transparant_icononly.png'
import { House, Settings } from '@mui/icons-material'
import { clearToken } from '../redux/dataslices/tokenSlice'
import { ConditionsMet } from "../helpers/dataHandlers/Inventarisation"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TranslateCustom as tc } from '../helpers/translation'

export default function NavbarDrawer(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const drawerOpen = useSelector(getDrawerOpen)
  const { t } = useTranslation()

  const onClickLogout = () => {
    dispatch(clearToken())
    navigate("/")
  }

  const onClickUserSettings = () => {
    if(props?.customerData && props?.customerData !== null) {
      navigate("/customer/".concat(props?.customerData?.id, "/settings"))
    }
    else {
      navigate("/settings")
    }
  }

  const onClickCustomers = () => {
    if(props?.customerData && props?.customerData !== null) {
      navigate("/customer/".concat(props?.customerData?.id, "/customers"))
    }
    else {
      navigate("/customers")
    }
  }

  const onClickPage = (pageNumber) => {
    navigate("/customer/".concat(props?.customerData?.id, "/", pageNumber))
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    dispatch(setDrawerOpen(open))
  }

  var pageData = []
  for (let pageNumber = 1; pageNumber < 100; pageNumber++) {
    try {
      var data = require('../pages/'.concat(pageNumber,'.json'))
      if(data?.dont_show_in_menu !== true && props?.customerData?.highest_page_reached >= pageNumber - 1 && ConditionsMet(props?.customerData, data?.conditions)) {
        pageData[pageNumber] = data
      }
      if(props?.customerData?.highest_page_reached < pageNumber) {
        break
      }
    }
    catch(e) {
      break
    }
  }

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* Header */}
      <Box
        marginBottom={2}
        sx={{
          width: '100%',
          maxWidth: 64,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          mt: 2
        }}
      >
        <img
          src={ActablueLogoNoText}
          alt='ActaBlue Logo'
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>
      <Divider />
      {/* Content */}
      <Box sx={{ flex: 1, overflow: 'auto', marginTop: 2, marginLeft: 0 }}>
        <List>
          <ListItem key={'Customers'} onClick={() => onClickCustomers()} onKeyDown={() => onClickCustomers()} disablePadding>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <House />
              </ListItemIcon>
              <ListItemText primary={t('customers')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'UserSettings'} onClick={() => onClickUserSettings()} onKeyDown={() => onClickUserSettings()} disablePadding>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </ListItemButton>
          </ListItem>
          {(props?.customerData && props?.customerData !== null) ?
          <>
            <ListItem key={'Customer'}>
              <ListItemText primary={props?.customerData?.relation_name ? props?.customerData?.relation_name : t("customer_without_name")} />
            </ListItem>
            {pageData.map((item, index) => (
              <ListItem key={'Customer_' + index} onClick={() => onClickPage(index)} onKeyDown={() => onClickPage(index)} disablePadding>
                <ListItemButton>
                  <ListItemText primary={tc(item.title)} />
                </ListItemButton>
              </ListItem>
            ))}
          </>
          :<></>}
        </List>
      </Box>
      <Box margin={2}>
        <Button
          fullWidth
          onClick={onClickLogout}
          variant="contained"
          color='secondary'
          sx={{ borderRadius: '9px' }}>
          {t("logout")}
        </Button>
      </Box>
      <Divider />
      {/* Footer */}
      <Typography variant="caption" sx={{ p: 2 }}>
        <CopyrightText />
        <VersionText />
      </Typography>
    </Box>
  )

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <SwipeableDrawer
        anchor={'right'}
        open={drawerOpen}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
        hysteresis={0.52}
        transitionDuration={350}
      >
        {list('right')}
      </SwipeableDrawer>
    </ThemeProvider>
  )
}