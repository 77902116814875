import React from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useParams } from "react-router-dom"
import { PDFViewer, Page, Text, Link, View, Document, Image } from '@react-pdf/renderer'
import { getCustomer, setCustomer, getHardware, setHardware, getQuoteConditions, setQuoteConditions } from "../redux/dataslices/inventarisationSlice"
import { GetCustomer, GetAllHardwareList, GetQuoteConditionsList } from "../helpers/dataHandlers/Inventarisation"
import { t, changeLanguage } from 'i18next'
import { TranslateCustom as tc } from '../helpers/translation'
import { styles } from "./quoteStyleSheet"
import companyInfo from '../quoteConfig/companyInfo.json'
import OneOffCosts from '../quoteConfig/oneOffCosts.json'
import RecurringCosts from '../quoteConfig/recurringCosts.json'
import ActablueLogofull from '../static/icons/logo_png_transparant.png'

function Quote(props) {
  const {customerId} = useParams()
  const dispatch = useDispatch()
  const token = useSelector(getToken)
  const customer = useSelector(getCustomer)
  const hardware = useSelector(getHardware)
  const quoteConditions = useSelector(getQuoteConditions)
  const [customerData, setCustomerData] = useState()
  const [quoteConditionsData, setQuoteConditionsData] = useState()
  const [hardwareData, setHardwareData] = useState()
  const [hasOneOffCosts, setHasOneOffCosts] = useState()
  const [hasRecurringCosts, setHasRecurringCosts] = useState()

  var temporaryCalculatedValue

  useEffect(() => {
    const fetchCustomer = async () => {
      GetCustomer(token, customerId)
        .then(response => { dispatch(setCustomer(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchHardware = async () => {
      GetAllHardwareList(token, customerId)
        .then(response => { dispatch(setHardware(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    const fetchQuoteConditions = async () => {
      GetQuoteConditionsList(token, customerId)
        .then(response => { dispatch(setQuoteConditions(response.data)) })
        .catch(error => {
          window.open("about:blank", "_self")
          window.close()
        })
    }
    setHasOneOffCosts(false)
    setHasRecurringCosts(false)
    fetchCustomer()
    fetchHardware()
    fetchQuoteConditions()
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (!customer) return
    setCustomerData(customer)
    if (customer.relation_language) {
      changeLanguage(customer.relation_language)
    }
    for (const category of Object.values(RecurringCosts)) {
      category.total = 0
      category.hasSubscriptions = false
      for (const value of Object.values(category.subscriptions)) {
        if(customer[value.relatedField] === true ||customer[value.relatedField] > 0) {
          category.total += value.pricePerMonth.toFixed(2) * (customer[value.relatedField] === true ? 1 : customer[value.relatedField])
          category.hasSubscriptions = true
          setHasRecurringCosts(true)
        }
      }
    }
    // eslint-disable-next-line
  }, [customer])
  
  useEffect(() => {
    if (!hardware) return
    let hardwareList = []
    for (const value of Object.values(OneOffCosts)) {
      hardwareList[value.hardwareType] = []
      hardwareList[value.hardwareType]["total"] = 0
    }
    for (const value of Object.values(hardware)) {
      setHasOneOffCosts(true)
      hardwareList[value.hardware_type]["total"] += value.hardware_quantity * value.hardware_sales_price.toFixed(2)
      hardwareList[value.hardware_type].push(value)
    }
    setHardwareData(hardwareList)
    // eslint-disable-next-line
  }, [hardware])
  
  useEffect(() => {
    if (!quoteConditions) return
    let quoteConditionsList = []
    for (const value of Object.values(quoteConditions)) {
      quoteConditionsList.push(value)
    }
    setQuoteConditionsData(quoteConditionsList)

    // eslint-disable-next-line
  }, [quoteConditions])

  const resetCalculatedValue = () => {
    temporaryCalculatedValue = 0
    return null
  }
  
  const addCalculatedValue = (value) => {
    temporaryCalculatedValue += value
    return null
  }

  const RenderQuote = () => (
    <Document>
      <FrontPage />
      <IndexPage />
      {hasOneOffCosts === true ?
        <>
          <OneOffCostsPage />
          <OneOffCostsTotalsPage />
        </>
      :null}
      {hasRecurringCosts === true?
        <>
          <RecurringCostsPage />
          <RecurringCostsTotalsPage />
        </>
      :null}
      <ConditionsPage />
      <ConfirmationPage />
    </Document>
  )

  const PageHeader = () => (  
    <View fixed style={styles.header_logo}>
      <Image src={ActablueLogofull} />
    </View>
  )

  const PageFooter = () => (
    <View fixed style={styles.footer}>
      <Text style={styles.footer_left}>
        {t("revision")} {customerData?.quote_revision_no ? customerData?.quote_revision_no : 1} {'\n'}
        {t("paraph")}:
      </Text>
      <Text style={styles.footer_right}>
        {companyInfo.website} / {companyInfo.telephone}
      </Text>
    </View>
  )

  const FrontPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.title_page}>
          <Text>
            {t("order_confirmation")} {'\n'}
            {customerData?.quote_name} {'\n'}
            {t("for")} {'\n'}
            {customerData?.relation_name}{'\n'}
            <Text style={styles.title_page_small}>{t("fao")} {customerData?.contact_name}</Text> {'\n'}
            <Text style={styles.title_page_small_bold}>{t("email")}: {customerData?.relation_email}</Text> {'\n'}
            <Text style={styles.title_page_small}>{t("telephone")}: {customerData?.relation_telephone}</Text> {'\n'}
            {t("revision")}: {customerData?.quote_revision_no ? customerData?.quote_revision_no : 1}
          </Text>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footer_left}>
          &nbsp; {'\n'}
          {t("iban")}: {companyInfo.iban} {'\n'}
          {t("bic")}: {companyInfo.bic} {'\n'}
          {t("vat_no")}: {companyInfo.vatNo} {'\n'}
          {t("chamber_of_commerce_no")}: {companyInfo.cocNo}
        </Text>
        <Text style={styles.footer_right}>
          {companyInfo.name} {'\n'}
          {companyInfo.address} {'\n'}
          {companyInfo.zipCode} {companyInfo.place} {'\n'}
          {companyInfo.email} {'\n'}
          {companyInfo.telephone}
        </Text>
      </View>
    </Page>
  )

  const IndexPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.index_table}>
        <Text style={styles.index_header}>{t("table_of_contents")}</Text>
        {hasOneOffCosts === true ?
        <>
          <Link src="#1_0" style={styles.index_category}>{t("one_off_costs")}</Link>
          {resetCalculatedValue()}
          {OneOffCosts ? OneOffCosts.map((item) => (hardwareData[item.hardwareType].length > 0 ?
              <Link
                key={"index_1_" + (temporaryCalculatedValue + 1)}
                src={"#1_" + (temporaryCalculatedValue + 1)}
                style={styles.index_item}
                >
                {addCalculatedValue(1)}
                <Text style={styles.index_item_number}>1.{temporaryCalculatedValue}</Text>
                <Text style={styles.index_item_description}>{tc(item.description)}</Text>
              </Link>
          :null)):null}
          <Link src="#2_0" style={styles.index_category}>{t("total_one_off_costs")}</Link>
          <Link src="#2_1" style={styles.index_item}>
            <Text style={styles.index_item_number}>2.1</Text>
            <Text style={styles.index_item_description}>{t("total")}</Text>
          </Link>
        </>
        :null}
        {hasRecurringCosts === true ?
        <>
          <Link src={"#" + ((hasOneOffCosts * 2) + 1) + "_0"} style={styles.index_category}>{t("recurring_costs")}</Link>
          {resetCalculatedValue()}
          {RecurringCosts ? RecurringCosts.map((item) => (true ?
              <Link 
                key={"index_" + ((hasOneOffCosts * 2) + 1) + "_" + (temporaryCalculatedValue + 1)}
                src={"#" + ((hasOneOffCosts * 2) + 1) + "_" + (temporaryCalculatedValue + 1)} 
                style={styles.index_item}
                >
                {addCalculatedValue(1)}
                <Text style={styles.index_item_number}>{(hasOneOffCosts * 2) + 1}.{temporaryCalculatedValue}</Text>
                <Text style={styles.index_item_description}>{tc(item.description)}</Text>
              </Link>
          :null)):null}
          <Link src={"#" + ((hasOneOffCosts * 2) + 2) + "_0"} style={styles.index_category}>{t("total_recurring_costs")}</Link>
          <Link src={"#" + ((hasOneOffCosts * 2) + 2) + "_1"} style={styles.index_item}>
            <Text style={styles.index_item_number}>{(hasOneOffCosts * 2) + 2}.1</Text>
            <Text style={styles.index_item_description}>{t("total")}</Text>
          </Link>
        </>
        :null}
        <Link src={"#" + ((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1) + "_0"} style={styles.index_category}>{t("conditions")}</Link>
        {quoteConditionsData ? quoteConditionsData.map((item, index) => (
          <Link 
            key={"index_" + ((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1) + "_" + index} 
            src={"#" + ((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1) + "_" + index} 
            style={styles.index_item}
            >
            <Text style={styles.index_item_number}>{(hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1}.{index}</Text>
            <Text style={styles.index_item_description}>{item.condition_name}</Text>
          </Link>
        )):null}
        <Link src={"#" + ((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 2) + "_0"} style={styles.index_category}>{t("order_confirmation")}</Link>
        <Link src={"#" + ((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 2) + "_1"} style={styles.index_item}>
          <Text style={styles.index_item_number}>{(hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 2}.1</Text>
          <Text style={styles.index_item_description}>{t("order_confirmation")}</Text>
        </Link>
      </View>
      <PageFooter/>
    </Page>
  )
  
  const OneOffCostsPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <Text id="1_0" style={styles.page_title}>{t("one_off_costs")}</Text>
        {resetCalculatedValue()}
        {OneOffCosts ? OneOffCosts.map((item) => (hardwareData[item.hardwareType].length > 0 ?
          <View
            key={"1_" + (temporaryCalculatedValue + 1)}
            >
            {addCalculatedValue(1)}
            <View style={styles.category}>
              <Text id={"1_" + temporaryCalculatedValue} style={styles.category_number}>1.{temporaryCalculatedValue}</Text>
              <Text style={styles.category_description}>{tc(item.description)}</Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.header_45_L}>{t("quantity")}</Text>
              <Text style={styles.header_60_L}>{t("brand")}</Text>
              <Text style={styles.header_270_L}>{t("description")}</Text>
              <Text style={styles.header_50_R}>{t("price")}</Text>
              <Text style={styles.header_50_R}>{t("amount")}</Text>
            </View>
            {hardwareData && hardwareData[item.hardwareType] ? hardwareData[item.hardwareType].map((line, index) => (index === "total"?null:
              <View 
                key={"1_" + temporaryCalculatedValue + "_" + index}
                style={styles.line}
                >
                <Text style={styles.line_45_L}>{line.hardware_quantity}</Text>
                <Text style={styles.line_60_L}>{line.hardware_brand}</Text>
                <Text style={styles.line_270_L}>{line.hardware_description}</Text>
                <Text style={styles.line_50_R}>{line.hardware_sales_price.toFixed(2)}</Text>
                <Text style={styles.line_50_R}>{(line.hardware_quantity * line.hardware_sales_price).toFixed(2)}</Text>
              </View>
            )):null}
          </View>
        :null)):null}
      </View>
      <PageFooter/>
    </Page>
  )

  const OneOffCostsTotalsPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <Text id="2_0" style={styles.page_title}>{t("total_one_off_costs")}</Text>
        <View style={styles.category}>
          <Text id="2_1" style={styles.category_number}>2.1</Text>
          <Text style={styles.category_description_short}>{t("total_amount")}</Text>
          <View style={styles.lines}>
            {resetCalculatedValue()}
            {OneOffCosts ? OneOffCosts.map((item,index) => (hardwareData[item.hardwareType].length > 0 ?
              <View 
                key={"2_1_" + index}
                style={styles.nonPaddedLine}
                >
                {addCalculatedValue((Number)(hardwareData[item.hardwareType]["total"].toFixed(2)))}
                <Text style={styles.line_310_L}>{tc(item.description)}</Text>
                <Text style={styles.line_10_L}>€</Text>
                <Text style={styles.line_50_R}>{hardwareData[item.hardwareType]["total"].toFixed(2)}</Text>
              </View>
            :null)):null}
            <View style={styles.totals}>
              <Text style={styles.header_310_L}>{t("total")}</Text>
              <Text style={styles.line_10_L}>€</Text>
              <Text style={styles.line_50_R}>{(Number)(temporaryCalculatedValue).toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </View>
      <PageFooter/>
    </Page>
  )

  const RecurringCostsPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <Text id={((hasOneOffCosts * 2) + 1) + "_0"} style={styles.page_title}>{t("recurring_costs")}</Text>
        <Text style={styles.page_subtitle}>{t("quote_recurring_costs_clarification")}</Text>
        {resetCalculatedValue()}
        {RecurringCosts ? RecurringCosts.map((category) => (category.hasSubscriptions === true ?
          <View
            key={((hasOneOffCosts * 2) + 1) + "_" + (temporaryCalculatedValue + 1)}
            >
            {addCalculatedValue(1)}
            <View style={styles.category}>
              <Text id={((hasOneOffCosts * 2) + 1) + "_" + temporaryCalculatedValue} style={styles.category_number}>{(hasOneOffCosts * 2) + 1}.{temporaryCalculatedValue}</Text>
              <Text style={styles.category_description}>{tc(category.description)}</Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.header_45_L}>{t("quantity")}</Text>
              <Text style={styles.header_60_L}>{t("brand")}</Text>
              <Text style={styles.header_270_L}>{t("description")}</Text>
              <Text style={styles.header_50_R}>{t("price")}</Text>
              <Text style={styles.header_50_R}>{t("amount")}</Text>
            </View>
            {category.subscriptions && category.subscriptions.map((item, index) => (customerData[item.relatedField] === true || customerData[item.relatedField] > 0 ?
              <View 
                key={((hasOneOffCosts * 2) + 1) + "_" + temporaryCalculatedValue + "_" + index}
                style={styles.line}
                >
                <Text style={styles.line_45_L}>{customerData[item.relatedField] === true? 1: customerData[item.relatedField]}</Text>
                <Text style={styles.line_60_L}>{companyInfo.name}</Text>
                <Text style={styles.line_270_L}>{tc(item.description)}</Text>
                <Text style={styles.line_50_R}>{item.pricePerMonth.toFixed(2)}</Text>
                <Text style={styles.line_50_R}>{((customerData[item.relatedField] === true? 1: customerData[item.relatedField]) * item.pricePerMonth).toFixed(2)}</Text>
              </View>
            :null))}
          </View>
        :null)):null}
      </View>
      <PageFooter/>
    </Page>
  )

  const RecurringCostsTotalsPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <Text id={((hasOneOffCosts * 2) + 2) + "_0"} style={styles.page_title}>{t("total_recurring_costs")}</Text>
        <Text style={styles.page_subtitle}>{t("quote_recurring_costs_clarification")}</Text>
        <View style={styles.category}>
          <Text id={((hasOneOffCosts * 2) + 2) + "_1"} style={styles.category_number}>{(hasOneOffCosts * 2) + 2}.1</Text>
          <Text style={styles.category_description_short}>{t("total_amount")}</Text>
          <View style={styles.lines}>
            {resetCalculatedValue()}
            {RecurringCosts ? RecurringCosts.map((item, index) => (item.hasSubscriptions === true ?
              <View 
                key={((hasOneOffCosts * 2) + 2) + "_1_" + index}
                style={styles.nonPaddedLine}
                >
                {addCalculatedValue((Number)(item.total.toFixed(2)))}
                <Text style={styles.line_310_L}>{tc(item.description)}</Text>
                <Text style={styles.line_10_L}>€</Text>
                <Text style={styles.line_50_R}>{item.total.toFixed(2)}</Text>
              </View>
            :null)):null}
            <View style={styles.totals}>
              <Text style={styles.header_310_L}>{t("total")}</Text>
              <Text style={styles.line_10_L}>€</Text>
              <Text style={styles.line_50_R}>{(Number)(temporaryCalculatedValue).toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </View>
      <PageFooter/>
    </Page>
  )

  const ConditionsPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <Text id={((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1) + "_0"} style={styles.page_title}>{t("conditions")}</Text>
        {resetCalculatedValue()}
        {quoteConditions ? quoteConditions.map((item) => (
          <View
            key={((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1) + "_" + temporaryCalculatedValue}
            wrap={false}
            >
            {addCalculatedValue(1)}
            <View style={styles.category}>
              <Text id={((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1) + "_" + temporaryCalculatedValue} style={styles.category_number}>{(hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 1}.{temporaryCalculatedValue}</Text>
              <Text style={styles.category_description}>{item.condition_name}</Text>
            </View>
            <View style={styles.line}>
              <Text style={styles.line_L}>{item.condition_contents}</Text>
            </View>
          </View>
        )):null}
      </View>
      <PageFooter/>
    </Page>
  )

  const ConfirmationPage = () => (
    <Page size="A4" style={styles.page}>
      <PageHeader/>
      <View style={styles.page_table}>
        <View style={styles.confirmation_page_category} id={((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 2) + "_0"}>
          <Text id={((hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 2) + "_1"} style={styles.confirmation_page_category_number}>{(hasOneOffCosts * 2) + (hasRecurringCosts * 2) + 2}.1</Text>
          <Text style={styles.confirmation_page_category_description}>{t("order_confirmation")}</Text>
        </View>
        <View style={styles.confirmation_page_line}>
          <Text style={styles.confirmation_page_decoration}>
            ..........................................................................................................................................
          </Text>
          <Text>
            <Text style={styles.confirmation_page_text}>
              {t("company_name")}&nbsp;&nbsp;
            </Text>
          </Text>
        </View>
        <View style={styles.confirmation_page_line}>
          <Text style={styles.confirmation_page_decoration}>
            ..........................................................................................................................................
          </Text>
          <Text>
            <Text style={styles.confirmation_page_text}>
              {t("authorised_person_name")}&nbsp;&nbsp;
            </Text>
          </Text>
        </View>
        <View style={styles.confirmation_page_line}>
          <Text style={styles.confirmation_page_decoration}>
            ..........................................................................................................................................
          </Text>
          <Text>
            <Text style={styles.confirmation_page_text}>
              {t("signature")}&nbsp;&nbsp;
            </Text>
          </Text>
        </View>
      </View>
      <PageFooter/>
    </Page>
  )

  return (
    <>
      <style>{`
        html {
          overflow: hidden
        }
      `}</style>
      <PDFViewer style={{width:"100%", height:"100%", border: 0, margin: 0, padding: 0}}>
        <RenderQuote />
      </PDFViewer>
    </>
  )
}

export default Quote
