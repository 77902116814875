import Moment from 'moment'
import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { Button, Box, CssBaseline, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

export default function CustomerTable(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onClickCustomer = (customer_guid) => {
    navigate("/customer/".concat(customer_guid,"/1"))
  }
  
  const onClickNewCustomer = () => {   
    const customer_guid = uuidv4()
    var dataObject
    var child_guid
    var defaultQuoteConditions
    try {
      defaultQuoteConditions = require('../defaults/quoteConditions.json')
    }
    catch(e) {
      defaultQuoteConditions = []
    }
    for(var i = 0; i < defaultQuoteConditions.length; i++) {  
      child_guid = uuidv4()
      dataObject = { id: child_guid, id_customer: customer_guid, id_owner: props?.id_owner, condition_order: i + 1, condition_name: defaultQuoteConditions[i].name, condition_contents: defaultQuoteConditions[i].contents } 
      props?.updateQuoteCondition(dataObject)
    }
    child_guid = uuidv4()
    dataObject = { id: child_guid, id_customer: customer_guid, id_owner: props?.id_owner, cashdrawer_name: t("cashdrawer_default_name"), cashdrawer_default_amount: 150 } 
    props?.updateCashDrawer(dataObject)
    dataObject = { id: customer_guid, id_owner: props?.id_owner }  
    props?.updateCustomer(dataObject)
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box>
        <Button
          onClick={onClickNewCustomer}
          variant="contained"
          color='secondary'
          sx={{ borderRadius: '9px', position: 'relative', top: '-35px', float: 'right'}}>
          {t("create_new_customer")}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("customer_name")}</TableCell>
              <TableCell align="right">{t("address")}</TableCell>
              <TableCell align="right">{t("zipcode")}</TableCell>
              <TableCell>{t("place")}</TableCell>
              <TableCell>{t("creation_date")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!props?.data || props?.data?.length === 0) &&
              <TableRow>
                  <TableCell>{t("no_customers_found")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
              </TableRow>
            }
            {props?.data?.map((item, index) => (
              <TableRow key={index} onClick={() => onClickCustomer(item.id)} onKeyDown={() => onClickCustomer(item.id)} hover={true} sx={{cursor: "pointer"}}>
                {item.relation_name ? (
                  <TableCell>{item.relation_name}</TableCell>
                ) : (
                  <TableCell>{t("customer_without_name")}</TableCell>
                )}
                <TableCell align="right">{item.relation_address}</TableCell>
                <TableCell align="right">{item.relation_zipcode}</TableCell>
                <TableCell>{item.relation_place}</TableCell>
                <TableCell>{Moment(item.creation).format('DD-MM-YYYY')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </ThemeProvider>
  )
}