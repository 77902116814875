import Card from "../components/card"
import CustomerTable from "../components/customerTable"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../redux/dataslices/tokenSlice"
import { useSnackbar } from "notistack"
import { getCustomers, setCustomers } from "../redux/dataslices/inventarisationSlice"
import { GetCustomersList, UpdateCustomer, UpdateCashDrawer, UpdateQuoteCondition } from "../helpers/dataHandlers/Inventarisation"
import { useNavigate, useOutletContext } from "react-router-dom"

function Customers() {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const customers = useSelector(getCustomers)
  const [customersData, setCustomersData] = useState()
  const context = useOutletContext()
  var requestsLeftToSend = 0

  const updateCustomer = (dataObject) => {
    UpdateCustomer(token, dataObject)
    .then(requestCompleted(dataObject.id))
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const updateCashDrawer = (dataObject) => {
    UpdateCashDrawer(token, dataObject)
    .then(requestCompleted(dataObject.id_customer))
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }
  
  const updateQuoteCondition = (dataObject) => {
    requestsLeftToSend ++
    UpdateQuoteCondition(token, dataObject)
    .then(requestCompleted(dataObject.id_customer))
    .catch(error => {
      enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
      setLoading(false)
    })
  }

  const requestCompleted = (customer_guid) => {
    requestsLeftToSend --
    if(requestsLeftToSend === -1) {
      navigate("/customer/".concat(customer_guid,"/1"))
    }
  }

  const customerListCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'list_of_customers',
    loading: loading,
    loadingText: "loading_customers",
    data: <CustomerTable 
      data={customersData} 
      id_owner={token.payload.sub}
      updateCustomer={updateCustomer}
      updateCashDrawer={updateCashDrawer}
      updateQuoteCondition={updateQuoteCondition}
      />
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      GetCustomersList(token)
        .then(response => { dispatch(setCustomers(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Inventarisation) ${error?.message}`, variant: 'error' })
          setLoading(false)
        })
    }
    fetchData()
    // eslint-disable-next-line
  }, [token])
  
  useEffect(() => {
    context.setCustomerData(null)
    if (!customers) return
    setLoading(true)
    let customersList = []
    for (const value of Object.values(customers)) {
      customersList.push(value)
    }
    setCustomersData(customersList)

    // eslint-disable-next-line
  }, [customers])

  useEffect(() => {
    if (customersData && customersData !== null) {
      setLoading(false)
    }
  }, [customersData])

  return (
      <>
        <Card {...customerListCardProps} />
      </>
  )
}

export default Customers
